export const environment = {
  BASE_URL: 'https://staging.nlplab.ai/api',
  REDIRECT_URI: 'https://app-staging.cosupport.ai/auth',
};

const serverEnvironment = {
  FETCHED: false,
};

export const getServerEnvironment = async () => {
  if (!serverEnvironment.FETCHED) {
    let env = await fetch('/api/env');
    env = await env.json();
    Object.assign(serverEnvironment, env, { FETCHED: true });
  }

  return serverEnvironment;
};
